@import "../../styles/_chunk";

.hover-dot-screenshot-carousel {
  transition: opacity 0.3s linear;
  opacity: 0;
  position: relative;

  .screenshot-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3));
  }

  @include theme(light) {
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
  }

  .screenshot-progress-dot-container {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    z-index: 1;
    height: 100%;

    .screenshot-progress-dot {
      width: 100%;
      height: 2.8px;
      box-sizing: border-box;
      background-color: hsla(0, 0%, 100%, 0.4);
      border-radius: 3.8px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      @include media-breakpoint-up(xl) {
        height: 4px;
      }
    }

    .active-dot {
      background-color: $white;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    .screenshot-progress-dot:not(:first-child) {
      margin-left: 8px;
    }
  }

  .screenshot-progress-dot-container-small {
    bottom: 8px;

    @include media-breakpoint-up(xl) {
      bottom: 15px;
    }
  }

  .screenshot-progress-dot-container-medium {
    bottom: 15px;
  }

  .screenshot-progress-dot-container-large {
    bottom: 18px;
  }

  .screenshot-fallback {
    background-color: $card-background-dark;
    height: 100%;
    width: 100%;
    position: absolute;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .screenshot-fallback-content {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $text-white;
      font-weight: 700;
      text-align: center;
      font-size: 1.2rem;
      padding-left: 3rem;
      padding-right: 3rem;

      @include theme(light) {
        color: map-get($light-theme, font-color);
      }
    }

    .screenshot-fallback-content-small {
      font-size: 1rem;
    }

    .screenshot-fallback-content-medium {
      font-size: 1.25rem;
    }

    .screenshot-fallback-content-large {
      font-size: 1.375rem;
    }
  }

  &.tall-screenshots {
    .screenshot-container {
      position: relative;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
      overflow: hidden;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .screenshot-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;

        img {
          width: auto;
          height: 100%;
        }

        img.screenshot-bg {
          position: absolute !important;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: auto;
          filter: blur(20px) brightness(0.9);
          transform: scale(1.1);
        }
      }

      .screenshot-fallback {
        top: 0;
        left: 0;
      }
    }
  }
}
